import React from 'react'
import { graphql } from 'gatsby'

import { Template404DataQuery } from '../../gatsby-types'
import NotFound from '../models/404/template'

interface IProps {
  data: Template404DataQuery
  path?: string
}

export default function NotFoundPage({ data, path }: IProps) {
  return <NotFound data={data} />
}

export const query = graphql`
  query TemplateNotFoundData {
    prismic404 {
      data {
        name {
          text
        }
        parentdocument {
          uid
          document {
            ... on PrismicHome {
              id
              data {
                name {
                  text
                }
              }
            }
          }
        }
        title {
          text
        }
        text {
          text
        }
        leftlinklabel {
          text
        }
        leftlinkurl {
          url
        }
        rightlinklabel {
          text
        }
        rightlinkurl {
          url
        }
        banner {
          alt
          url
        }
        subtitle {
          text
        }
        body {
          ... on Prismic404BodyFooterCards {
            slice_type
            items {
              footercardtitle {
                text
              }
              footercardsubtitle {
                text
              }
              footercardtext {
                text
              }
              footercardlinklabel {
                text
              }
              footercardlinkurl {
                url
              }
              footercardbackground {
                url
              }
            }
          }
        }
      }
    }
    allPrismicBlogPost(
      sort: { order: DESC, fields: data___postdate }
      filter: { data: { parentdocument: { uid: { eq: "actualites" } } } }
      limit: 6
    ) {
      nodes {
        uid
        data {
          parentdocument {
            uid
            document {
              ... on PrismicCatalog {
                data {
                  name {
                    text
                  }
                }
              }
              ... on PrismicNews {
                data {
                  name {
                    text
                  }
                }
              }
              ... on PrismicPodcasts {
                data {
                  name {
                    text
                  }
                }
              }
            }
          }
          postcategory
          postsecondarycategories {
            category
          }
          posttemplate
          posttitle {
            text
          }
          postdescription {
            html
          }
          postmainimage {
            alt
            url
          }
          postthumbnailimage {
            alt
            url
          }
          postintroductiontext {
            html
          }
          postdate(locale: "fr", formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`

NotFoundPage.query = query
