import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { ILink } from '../../../types/custom'

interface IProps {
  links: ILink[]
}

export default function Breadcrumb({ links }: IProps) {
  return (
    <Container>
      {links &&
        links.map((link, index) => (
          <div key={index}>
            {index > 0 && <span>•</span>}
            <Link to={link.url}>{link.label}</Link>
          </div>
        ))}
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > div > a {
    font-size: 14px;
    opacity: 0.8;
    text-transform: uppercase;

    :hover {
      opacity: 0.56;
    }
  }

  & > div > span {
    opacity: 0.8;
    margin: 0 4px;
  }
`
